import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-autorizado',
  templateUrl: './no-autorizado.component.html',
  styles: [
  ]
})
export class NoAutorizadoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
