import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: [
  ]
})
export class HeaderComponent implements OnInit {

  get auth(){
    //Es el servicio el que tiene la informacion del usuario logueado
    return this.authService.auth;
  }

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

}
