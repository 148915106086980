import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ComunicacionService {

  constructor() { }

  filtro_seleccionado!:number;
  private enviarFiltroSubject = new Subject<number>();
  enviarMensajeObservable = this.enviarFiltroSubject.asObservable();

  private viendoDetalleSubject = new Subject<boolean>();
  viendoDetalleObservable = this.viendoDetalleSubject.asObservable();


  enviarMensaje(filtro_seleccionado: number){
    this.filtro_seleccionado = filtro_seleccionado;
    this.enviarFiltroSubject.next(filtro_seleccionado);
  }

  viendoDetalle(viendo:boolean){
    this.viendoDetalleSubject.next(viendo);

  }


}
