
<div class="flex justify-content-between flex-wrap header header">
  <div class="flex justify-content-start align-items-center">
    <img class="" src="../assets/images/logo_uahc_h_s_white.png" width="150">
  </div>
  <!-- <div class="col-3"></div> -->
  <div class="flex justify-content-end align-items-center">
    <div class="">
      <div class="flex justify-content-end flex-wrap container ">
        <div class="flex align-items-center justify-content-center m-2">
          <div class="nombre_usuario">
            {{ auth.nombres | titlecase }} {{ auth.paterno | titlecase }} {{ auth.materno | titlecase}}
          </div>
        </div>
        <div class="flex align-items-center justify-content-center m-2">
          <p-avatar icon="pi pi-user" styleClass="mr-2" [style]="{'background-color': '#9c27b0', 'color': '#ffffff'}" shape="circle"></p-avatar>
        </div>
      </div>
  </div>
  </div>
</div>






