import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Filtro } from '../interfaces/filtro.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FiltrosService {

  constructor(private http:HttpClient) { }

  listaFiltros(){
    return this.http.get<Filtro[]>(`${ environment.baseUrl }/v1/filtro/`);
  }

}
