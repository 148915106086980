import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { environment } from 'src/environments/environment';
import { Auth } from '../interfaces/auth.interface';
import { Persona } from '../interfaces/persona.interface';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private baseUrl: string = environment.baseUrl;
  private _auth: Auth | undefined;

  get auth(){
    //Se envia entre {} para desestructurarlo
    return { ...this._auth }
  }

  constructor( private http: HttpClient) { }

  verificaAutenticacion(): Observable<boolean>{

    if ( !localStorage.getItem('token') || localStorage.getItem('token') === 'undefined'){
      return of(false);
    }

    return this.http.get<Auth>(`${ this.baseUrl }/v1/titulado/${ localStorage.getItem('token') }`)
            .pipe(
              map( auth => {
                //Carga la informacion del usuario logueado en _auth para mantener la informacion
                this._auth = auth;
                return true;
              } )
            );
  }
  login(rut:string){
    return this.http.get<Auth>(`${ this.baseUrl }/v1/titulado/${ rut }`)
                .pipe(
                  //tap( resp => console.log('Auth service', resp) )
                  tap( auth => this._auth = auth ),
                  tap( auth => localStorage.setItem('token', auth.rut) ),
                )
  }

  persona(rut:any){
    return this.http.get<Persona>(`${ this.baseUrl }/v1/persona/${ rut }`)
  }
}
