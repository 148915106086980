import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Filtro } from '../interfaces/filtro.interface';
import { FiltrosService } from '../services/filtros.service';
import { CategoriasService } from '../services/categorias.service';
import { Categoria } from '../interfaces/categoria.interface';
import { ComunicacionService } from '../services/comunicacion.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styles: [
  ]
})
export class SidebarComponent implements OnInit {


  lista_filtros: Filtro[] = [];
  lista_categorias: Categoria[] = [];

  filtro_seleccionado!: number;



  constructor(private filtrosService:FiltrosService,
              private categoriasService:CategoriasService,
              private comunicacionService:ComunicacionService) { }

  ngOnInit(): void {
    this.cargarCategorias();
    this.cargarFiltros();
    this.comunicacionService.enviarMensajeObservable.subscribe({
      next: (filtro) => {
        this.filtro_seleccionado = filtro;
      }
    })
  }

  tocaInput(filtro_id:number){

    this.comunicacionService.enviarMensaje(filtro_id);

  }


  cargarCategorias(){
    this.categoriasService.listaCategorias()
      .subscribe({
        next: (categorias) => {
          this.lista_categorias = categorias;
        },
        error: (e) => {
          console.log(e);
        }
      })
  }

  cargarFiltros(){
    this.filtrosService.listaFiltros()
      .subscribe({
        next:(filtros) => {
          this.lista_filtros = filtros;
        },
        error:(e) => {
          console.log(e);
        }
      })
  }


}
