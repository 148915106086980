import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/guards/auth.guard';
import { NoAutorizadoComponent } from './auth/no-autorizado/no-autorizado.component';
import { LoginComponent } from './auth/pages/login/login.component';


const routes: Routes = [
  {
    path: 'auth',
    component: LoginComponent
  },
  {
    path: 'ofertas',
    loadChildren: () => import('./bolsa-trabajo/bolsa-trabajo.module').then( m => m.BolsaTrabajoModule),
    canLoad: [ AuthGuard ],
    canActivate: [ AuthGuard ]
  },
  {
    path: 'error',
    component: NoAutorizadoComponent
  },
  {
    path:'**',
    redirectTo: 'auth'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
