<div class="p-card" style="height: 100%;">
  <div class="p-card-body">
    <div class="p-card-content-filtro">
      <div class="titulo">Filtrar por</div>
      <hr>
      <div *ngFor="let categoria of lista_categorias">
        <div class="texto-medio mb-1">{{ categoria.nombre | titlecase }}</div>
        <div *ngFor="let filtro of lista_filtros; let i = index">
          <div *ngIf="filtro.categoria == categoria.id " class="mb-1">
            <p-inputSwitch (onChange)="tocaInput(filtro.id)" class="mr-1"></p-inputSwitch>
            <label>{{ filtro.nombre | titlecase }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

